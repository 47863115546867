import store from "@/store"

function updateJobStatus(state, batchJob, val){
    var status = val.status
    switch (status) {
        case "ml_start":
            /*
            sometimes this comes before the job has actually loaded, which
            means the status can't be updated
            */
            batchJob.json_started = true
            batchJob.json_running = true
            break

        case "ml_end":
            batchJob.json_success = true
            batchJob.json_running = false
            //just mark this for now
            batchJob.json_started = true
            break

        case "ml_error":
            batchJob.json_success = false
            batchJob.json_running = false
            break

        case "export_start":
            batchJob.export_started = true
            batchJob.export_running = true
            break

        case "export_end":
            batchJob.export_success = true
            batchJob.export_running = false
            batchJob.is_complete = true
            batchJob.is_success = true
            console.log('in batch job update export end.  num pages is:' , val.pages)
            state.totalPages = state.totalPages += val.pages
            break

        case "export_error":
            batchJob.export_success = false
            batchJob.export_running = false
            batchJob.is_complete = true
            batchJob.is_success = false
            break

        default:
            //nothing here
            break
    }
}

export default {

	SET_BATCH_JOBS(state, val) {
		state.batchJobs = val
	},
    SET_BATCH_JOB(state, val){
        state.batchJob = val
    },

    SET_TOTAL_PAGES(state, val){
        state.totalPages = val
    },
    
    UPDATE_BATCH_JOB(state, val){
        //update messages are streaming from backend.  we get a job_key, doc_key, filename, and status in message
        //need to take the message and add the job object w/status to the jobs list if it's not there, or
        //update the job's status if it is there.  so only add and modify; don't need to remove/replace

        //the val param is the websocket message

        //is it there?
        var found = false
        var batchJob = null
        for (var i = 0; i < state.batchJobs.length; i++) {	
            if (state.batchJobs[i].key == val.job_key) {
                console.log('found the batchJob in the jobs')
                found = true
                batchJob = state.batchJobs[i]
                break
            }
        }
        //found
        if(found == true){
            //update it
            updateJobStatus(state, batchJob, val)
            //store.commit("UPDATE_JOB_STATUS", batchJob, val)
        }
        //not found
        else{
            console.log('batch job not found, adding new one')
            //create new object
            batchJob = {
                pages: 0,
                key: val.job_key,
                doc_key: val.doc_key,
                doc_name: val.filename,  
                json_started: false,
                json_running: false,
                json_success: false,
                export_started: false,
                export_running: false,
                export_success: false,
                is_complete: false,
                is_success: false
            }
            //update status
            updateJobStatus(state, batchJob, val)
            //store.commit("UPDATE_JOB_STATUS", batchJob, val)
            state.batchJobs.push(batchJob)
        }
    },

	UPDATE_BATCH_JOB_OLD(state, val) {

		//need to update the appropriate values in the batch jobs
		//console.log('batch jobs are: ', state.batchJobs)
		//console.log('value to update is: ', val)
		var batchJob = null
		for (var i = 0; i < state.batchJobs.length; i++) {
			if (state.batchJobs[i].key == val.job_key) {
				batchJob = state.batchJobs[i]
				console.log('in UPDATE_BATCH_JOB.  found batch job for job_key: ', val.job_key)
			}
		}
		if (batchJob != null) {
			const status = val.status
			switch (status) {
				case "ml_start":
					/*
					sometimes this comes before the job has actually loaded, which
					means the status can't be updated
					*/
					batchJob.json_started = true
					batchJob.json_running = true
					break

				case "ml_end":
					batchJob.json_success = true
					batchJob.json_running = false
					//just mark this for now
					batchJob.json_started = true
					//update page count
                    console.log('in batch job update ml end.  num pages is:' , val.num_pages)
					batchJob.pages = val.num_pages
					break

				case "ml_error":
					batchJob.json_success = false
					batchJob.json_running = false
					break

				case "export_start":
					batchJob.export_started = true
					batchJob.export_running = true
					break

				case "export_end":
					batchJob.export_success = true
					batchJob.export_running = false
					batchJob.is_complete = true
					batchJob.is_success = true
                    console.log('in batch job update export.  num pages is:' , val.num_pages)
                    batchJob.pages = val.pages

					break

				case "export_error":
					batchJob.export_success = false
					batchJob.export_running = false
					batchJob.is_complete = true
					batchJob.is_success = false
					break

				default:
					//nothing here
					break
			}
		}
        return batchJob

	}
}
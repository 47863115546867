export default {
	getBatches(state){
		return state.batches
	},
	getBatch(state){
		return state.batch
	},
	//the running batch key
	getRunningBatchKey(state){
		return state.runningBatchKey
	},
    getNumDocsInBatch(state){
        return state.numDocsInBatch
    },
}
export default {
	SET_BATCHES(state, val){
		state.batches = val
	},
	SET_BATCH(state, val){
		state.batch = val
	},
	SET_RUNNING_BATCH_KEY(state, val){
		state.runningBatchKey = val
	},
    SET_BATCH_NUM_DOCS(state, val){
        state.numDocsInBatch = val
    }, 
	DELETE_BATCH(state, batch_id){
		for(var i=0; i<state.batches.length; i++){
			if(state.batches[i].key == batch_id){
				console.log('found deleted key in batches - removing')
				state.batches.splice(i, 1)
				break;
			}
		}
	},
	UPDATE_BATCH_ELAPSED_SECS(state, val){
		state.batch.elapsed_secs = val
	},
	UPDATE_BATCH_TOTAL_PAGES(state, val){
		state.batch.total_pages = val
	},
	UPDATE_BATCH_NUM_DOCS(state, val){
		state.batch.num_docs = val
	},
}
export default {
	getBatchJobs(state){
        return state.batchJobs
    },
    getBatchJob(state){
        return state.batchJob
    },

    getTotalPages(state){
        return state.totalPages
    }
}
import HTTP from "@/helpers/http-common"

export default {

	async fetchBatches({ dispatch, commit}, {payload}) {
		return new Promise((resolve, reject) => {
			var url = "/batch/api/output_batch"
            if(payload != undefined && payload.split_process == true){
                url = url + "?split_process=true"
            }
			HTTP.get(url)
				.then((response) => {
					console.log('fetch batches worked!')
					//layouts are in response.data
					if (response.data != null) {
						console.log('response from fetchBatches:', response)
						//set the value ie 'state' of layouts in the store
						commit("SET_BATCHES", response.data.data)
						resolve("success")
					}
					
				}).catch((error) => {
					console.log('error getting batches')
					reject(error)
				})
		})
	},

	async fetchBatch({ dispatch, commit }, { payload }) {
		return new Promise((resolve, reject) => {

			HTTP.get("/batch/api/output_batch/" + payload.batch_key)
				.then((response) => {
					console.log('response:', response)
						//set the value ie 'state' of layouts in the store
						commit("SET_BATCH", response.data.data)
						resolve('success')
				}).catch((error) => {
					console.log("error getting batch")
					reject(error)
				})
		})
	},

	//unused for now
	async validateBatchDocuments( {dispatch, commit}, {payload}){
		return new Promise((resolve, reject) => {

			HTTP.post("/batch/api/batch_document_validation",
			{
				batch_document_keys : payload.selectedDocuments
			}).then( (response) =>{
				var batchDocumentKeys = response.data.data
				if(batchDocumentKeys != null && batchDocumentKeys.length > 0){
					resolve(false) //invalid
				}
				else{
					resolve(true) //valid
				}
			}).catch((error) => {
				console.log("error validating batch docs")
				reject(error)
			})


		})
	},

	/*
		This is the call to start running the batch process
	*/
	async runBatch( {dispatch, commit}, {payload}){	
		return new Promise((resolve, reject) => {
			console.log("payload.channel ==>", payload.channel)
			HTTP.post("/batch/api/batch_run", 
			{
				batch_type: payload.batch_type,
				import_type: payload.import_type,
                zones_only: payload.zones_only, // new for zones-only
                batch_name: payload.batch_name != undefined && payload.batch_name != null ? payload.batch_name : null,
				template: payload.selectedTemplate,
				batch_documents: payload.batch_documents,
				file_type: payload.file_type,
				channel: payload.channel
			}).then( (response) => {
				console.log('in run batch action. running batch key: ', response.data.data.batch_key)
				commit("SET_RUNNING_BATCH_KEY", response.data.data.batch_key)
                commit("SET_BATCH_NUM_DOCS", response.data.data.num_docs)
				console.log('resolving success in runBatch')
				resolve('success')
			}).catch( (error) => {
				reject(error)
			})
		})
	},

	async deleteBatch({ dispatch, commit }, { payload }) {		
		return new Promise((resolve, reject) => {
			
			HTTP.delete("/batch/api/output_batch/" + payload.batch_key)
				.then((response) => {
					resolve('success')
					commit("DELETE_BATCH", payload.batch_key)
				}).catch((error) => {
					console.log('error deleting batch', error)
					reject(error)
				})
		})
	},
}